/* global $ */
import '@lottiefiles/lottie-player'

class App
{
    bootstrap;

    constructor()
    {
        import(/* webpackChunkName: "slick" */ './components/slick').then(() => {
            var $el = $(".banner-slider");
            $el.on("init", function () {
                $("lottie-player").each(function () {
                    var players = $(this).attr("data-src");
                    this.load(players);
                });
            });
            $el.slick({
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: false,
                autoplay: true,
                autoplaySpeed: 5000,
                speed: 1200,
                rows: 0
            });
        });
        document.documentElement.style.setProperty('--window-width', document.body.clientWidth + 'px');
        document.documentElement.style.setProperty('--window-height', window.innerHeight + 'px');
        this.disableLogger();
        this.getBootstrap();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initForms($('form'));
        this.initMatrixBlocks();
        this.initTextBtns();
        $('body').css('opacity', 1);
        $(window).resize(() => {
            document.documentElement.style.setProperty('--window-width', document.body.clientWidth + 'px');
            if ($(window).width() > 768) {
                document.documentElement.style.setProperty('--window-height', window.innerHeight + 'px');
            }
            this.initTextBtns();
        });
        console.log('App initialized');
    }

    getBootstrap()
    {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    disableLogger()
    {
        if (window.Globals.env == 'production') {
            console.log = function() {};
        }
    }

    initTextBtns()
    {
        if ($('.text-btns').length) {
            $('.text-btns').each(function() {
                let $this = $(this);
                let $items = $this.find('.btn');
                let maxwidth = 0;
                $items.each(function() {
                    if ($(this).width() > maxwidth) {
                        maxwidth = $(this).width();
                    }
                });
                $items.width(maxwidth);
            });
        }
    }

    initForms($forms)
    {
        if ($forms.length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($forms, (i, elem) => {
                    new chunk.Form($(elem));
                });
            });
        }
    }

    initMatrixBlocks()
    {
        if ($('.matrix-block').length) {
            import(/* webpackChunkName: "matrix" */ './components/matrix');
        }
    }

    initToasts($elems)
    {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Toast(elem).show();
                });
            });
        }
    }

    initTooltips($elems)
    {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Tooltip(elem);
                });
            });
        }
    }

    addAlert(message, $elem, type = "danger", dismissible = false)
    {
        let _class = "alert alert-" + type;
        if (dismissible) {
            _class += ' alert-dismissible fade show';
            message += '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>';
        }
        $('<div class="' + _class + '">' + message + '</div>').appendTo($elem);
    }

    addAlerts(messages, $elem, type = "danger", dismissible = false)
    {
        for (let i in messages) {
            this.addAlert(messages[i], $elem, type, dismissible);
        }
    }
}

export default App;
